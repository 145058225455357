@font-face {
  font-family: 'CircularStd';
  src: local('MyFont'),
    url(./fonts/CircularStd-Book-ed76eb21560f0dc038cce0adfd65ea3c.woff)
      format('woff');
  font-display: swap;
}

body {
  font-family: 'CircularStd', Arial, sans-serif;
}
