@import './custom/variables';

$grid-breakpoints: (
  xs: 0,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl,
  xxl: $xxl,
);

$container-max-widths: (
  sm: $sm,
  sml: $cmw-sm,
  md: $cmw-md,
  lg: $cmw-lg,
  xl: $cmw-xl,
  xxl: $cmw-xxl,
);

$primary: $f-black;
$danger: $f-primary;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$form-check-input-active-filter: brightness(100%);
$modal-backdrop-bg: rgba(83, 86, 90, 0.8);
$modal-backdrop-opacity: 1;
$position-values: (
  0: 0,
  50: 50%,
  100: 100%,
  scroll-btn-x: calc(100% - 5rem),
  scroll-btn-y: calc(100% - 5rem),
);

@import '../../node_modules/bootstrap/scss/bootstrap';

.border-indigo {
  border-color: $indigo !important;
}

.text-indigo {
  color: $indigo;
}

.scroll-margin-top {
  scroll-margin-top: 94px;

  @include media-breakpoint-up(md) {
    scroll-margin-top: 115px;
    scroll-snap-margin-top: 115px;
  }

  @include media-breakpoint-up(xl) {
    scroll-margin-top: 140px;
    scroll-snap-margin-top: 140px;
  }
}

.btn {
  padding: $btn-padding-y $btn-padding-x;
  border-radius: $btn-border-radius;

  &.btn-primary {
    border: 2px solid $f-black;
    color: $f-black;
    font-size: $font-size-18;
    background-color: $white;

    @media (pointer: fine) {
      &:hover {
        background-color: $f-black;
        border: 2px solid $f-black;
        color: $white;
      }
    }

    &:focus {
      border: 2px solid $f-black;
      background-color: $f-black;
      color: $white;
    }

    &:disabled {
      border: 2px solid $white;
      background-color: $f-cool-gray-200;
      color: $white;
    }

  }

  &.btn-sm {
    font-size: $btn-font-size-sm;
    padding: $btn-padding-y-sm $btn-padding-x-sm;
  }

  &.btn-outline {
    border: $btn-border-width solid $f-black;
    color: $f-black;
    background-color: transparent;

    @media (pointer: fine) {
      &:hover {
        border: $btn-border-width solid $f-primary;
        color: $f-primary;
      }
    }

    &:focus {
      border: $btn-border-width solid $f-cool-gray;
      color: $f-cool-gray;
    }

    &:disabled {
      border: $btn-border-width solid $f-cool-gray-200;
      color: $f-cool-gray-200;
    }

    &.active {
      border: $btn-border-width solid $f-primary;
      color: $f-primary;
    }
  }
}

.color-btn {
  width: 24px;
  height: 24px;
  border: 1px solid #b0b0b0;
  margin: 4px 2px;
}

.color-btn-selected {
  top: 0px;
  left: 3px;
  width: 18;
  height: 18px;
}

.color-btn-border-current {
  border-color: $f-primary-100;
  box-shadow: 0px 0px 0px 1px $f-primary-100;
  outline-color: $f-primary-100;
  transition: all 0.1s ease;
}

.scroll-button {
  background-color: $f-primary-100;
  padding: 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease-in-out;
}

//typography

.display-1 {
  font-size: $font-size-36;
  line-height: $line-height-110;
  font-weight: $font-weight-500;

  @include media-breakpoint-only(md) {
    font-size: $font-size-48;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-56;
  }
}

.display-2 {
  font-size: $font-size-30;
  line-height: $line-height-120;
  font-weight: $font-weight-500;

  @include media-breakpoint-only(md) {
    font-size: $font-size-40;
    line-height: $line-height-110;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-48;
    line-height: $line-height-110;
  }
}

.heading-1 {
  font-size: $font-size-30;
  line-height: $line-height-120;
  font-weight: $font-weight-500;

  @include media-breakpoint-only(md) {
    font-size: $font-size-34;
    line-height: $line-height-115;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-40;
    line-height: $line-height-110;
  }
}

.heading-2 {
  font-size: $font-size-24;
  line-height: $line-height-140;
  font-weight: $font-weight-500;

  @include media-breakpoint-only(md) {
    font-size: $font-size-28;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-34;
    line-height: $line-height-130;
  }
}

.heading-3 {
  font-size: $font-size-22;
  line-height: $line-height-130;
  font-weight: $font-weight-500;

  @include media-breakpoint-only(md) {
    font-size: $font-size-24;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-28;
  }
}

.heading-4 {
  font-size: $font-size-20;
  line-height: $line-height-130;
  font-weight: $font-weight-500;

  @include media-breakpoint-up(lg) {
    font-size: $font-size-22;
  }
}

.heading-5 {
  font-size: $font-size-18;
  line-height: $line-height-130;
  font-weight: $font-weight-500;

  @include media-breakpoint-only(md) {
    font-size: $font-size-18;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-18;
  }
}

.heading-6 {
  font-size: $font-size-16;
  line-height: $line-height-130;
  font-weight: $font-weight-500;

  @include media-breakpoint-only(md) {
    font-size: $font-size-16;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-16;
  }
}

.paragraph-1 {
  font-size: $font-size-20;
  line-height: $line-height-150;
  font-weight: $font-weight-450;
}

.paragraph-2 {
  font-size: $font-size-18;
  line-height: $line-height-150;
  font-weight: $font-weight-450;
}

.paragraph-3 {
  font-size: $font-size-16;
  line-height: $line-height-150;
  font-weight: $font-weight-450;
}

.paragraph-4 {
  font-size: $font-size-14;
  line-height: $line-height-150;
  font-weight: $font-weight-450;
}

.paragraph-5 {
  font-size: $font-size-12;
  line-height: $line-height-150;
  font-weight: $font-weight-450;
}

.bold {
  font-weight: $font-weight-700;
}

.cursor-pointer {
  cursor: pointer;
}

//non-clickable buttons

.non-clickable-btn {
  font-size: $font-size-12;
  line-height: $line-height-150;
  background-color: transparent;
  padding: $space-2 $space-8;
}

//logo

.logo {
  height: auto;
  width: 79px;

  @include media-breakpoint-up(md) {
    height: auto;
    width: 108px;
  }

  @include media-breakpoint-up(xl) {
    height: auto;
    width: 120px;
  }
}

//margin-x for header

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 94px;
  z-index: 100;
  position: relative;

  @include media-breakpoint-up(md) {
    height: 115px;
  }

  @include media-breakpoint-up(xl) {
    height: 140px;
  }
}

.nav-container {
  @extend .container-fluid;
  padding: 0 24px;
  max-width: $xxl;

  @include media-breakpoint-only(md) {
    padding: 0 51px;
  }

  @include media-breakpoint-up(lg) {
    padding: 0 65px;
  }

  @include media-breakpoint-up(xxl) {
    padding: 0 135px;
  }
}

.offcanvas.offcanvas-end {
  border: none;
  width: 344px;

  @include media-breakpoint-up(md) {
    width: 420px;
  }
}

.offcanvas-backdrop.show {
  opacity: 1;
  background-color: rgba(83, 86, 90, 0.8);
  backdrop-filter: blur(8px);
}

.offcanvas-header {
  height: 80px;
  padding: 25px 24px;
}

.offcanvas-logo {
  height: 30px;
}

.offcanvas-body {
  background-color: $f-cool-gray-300;
}

.offcanvas-border {
  border-bottom: 0.1rem solid rgba(83, 86, 90, 0.25);
}

.container-md {
  padding: 0 12px;

  @include media-breakpoint-down(md) {
    padding: 0 24px;
  }

  @include media-breakpoint-only(md) {
    padding: 0 51px;
  }

  @include media-breakpoint-up(lg) {
    padding: 0 65px;
  }

  @include media-breakpoint-up(xl) {
    padding: 0 135px;
  }
}

//alert

.alert-header {
  z-index: 99;
  max-width: $cmw-xxl;
  margin: 0 auto;
}

.top-115 {
  top: 94px;

  @include media-breakpoint-up(md) {
    top: 115px;
  }

  @include media-breakpoint-up(xl) {
    top: 140px;
  }
}

.alert-text {
  @extend .paragraph-3;
  max-width: 800px;
  padding: 18px 0;
  margin: 0 auto;
}

//language dropdown

.dropdown-menu {
  min-width: inherit;
}

//button link

.btn-link {
  color: $f-black;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.25s ease;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
    color: $f-primary;
  }

  @media (hover: none) {
    &:hover {
      color: $f-black;
    }
  }
}

//header buttons (login, menu, cart)

.header-btn {
  @extend .paragraph-4;
  @extend .btn-link;
  text-decoration: none;

  .header-btn-icon {
    height: 24px;
    width: 24px;
  }

  .header-btn-label {
    transition: all 0.25s ease;
  }

  &:hover .header-btn-label {
    text-decoration: underline;
    text-underline-offset: 3px;
    color: $f-primary;
  }

  @media (hover: none) {

    &:hover .header-btn-label,
    .header-btn-icon {
      text-decoration: none;
      color: $f-black;
    }
  }
}

//search box (all screens)

@mixin box-size {
  height: 56px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin box-border {
  border: 2px solid $f-black;
  padding: 0 15px;
}

@mixin box-input {
  width: 240px;
  padding: 0 6px;
}

.outline {
  @include box-border;

  .search-label {
    display: none;
  }
}

.header-group {
  // position: absolute;
  // top: 50%;
  // left: 0%;
  // transform: translate(-100%, -50%);
}

.search-box {
  @include box-size;

  &.search-input:focus,
  .search-input:not(:placeholder-shown) {
    @include box-input;
  }

  &.search-box:hover,
  &.search-box:focus-within {
    @extend .outline;

    .search-input {
      @include box-input;
    }
  }
}

.search-box-drawer {
  @include box-size;
  @extend .outline;

  .search-input {
    @include box-input;
    width: 200px;
  }

  &.search-input:focus,
  &.search-input:not(:placeholder-shown) {
    @include box-input;
    width: 200px;
  }
}

.search-input {
  outline: none;
  border: none;
  background: none;
  width: 0;
  padding: 0;
  float: left;
  font-size: $font-size-18;
  transition: 0.3s;
  line-height: 40px;
}

.search-btn {
  background-color: transparent;
  position: relative;
  float: right;
  transition: 0.3s;
}

.search-label {
  @extend .paragraph-4;
  transition: 0.3s;
}

@media (prefers-reduced-motion: no-preference) {
  .loading {
    animation: loading-spin infinite 1.5s linear;
  }
}

@keyframes loading-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.card-overlay {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(1px);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.card-title {
  font-size: $font-size-18;

  @include media-breakpoint-up(sm) {
    font-size: $font-size-20;
  }
}

.card-title-top {
  font-size: $font-size-14;
  padding-bottom: 15px;

  @include media-breakpoint-up(sm) {
    font-size: $font-size-16;
    margin-left: 8px;
    margin-bottom: -2px;
    padding-bottom: 0px;
  }
}


.product-image-wrapper {
  margin-right: 30px;

  @include media-breakpoint-up(sm) {
    margin-right: 0;
  }
}

.card-image-text {
  padding-top: 20px;

  @include media-breakpoint-up(sm) {
    padding-top: 0;
  }
}

.custom-sm-width {
  @media (min-width: 500px) and (max-width: 767.98px) {
    width: 50%;
  }
}

.product-image {
  max-width: 70%;
  max-height: 70%;


  @include media-breakpoint-up(sm) {
    max-width: 320px;
    max-height: 320px;
    margin: 0
  }
}

.generic-product-image {
  max-width: 100%;
  max-height: 100%;

  @include media-breakpoint-up(sm) {
    padding: 25%;
  }
}

.card-attribute {
  font-size: $font-size-15;

  @include media-breakpoint-up(sm) {
    font-size: $font-size-16;
  }
}

.modal {
  backdrop-filter: blur(8px);
}

.modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  border: 0;
  border-radius: 0;
  height: 400px;
  max-width: 400px;
}

.modal-body,
.modal-footer {
  background-color: $f-cool-gray-300;
}

.form-control {
  border-radius: 0;
}

input[type='number'] {

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
}

.form-check input[type='checkbox'] {
  border-radius: 0;
}

.selected-product-card {
  width: 120px;

  @include media-breakpoint-up(sm) {
    width: 130px;
  }

  @include media-breakpoint-up(md) {
    width: 160px;
  }
}

.contact-us {
  display: flex;
  flex-flow: row;
  align-items: center;

  @media (max-width: 800px) {
    flex-flow: column;
    gap: 10px;
  }
}

.mobile-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px
}

.mobile-footer-select {
  margin-left: -20px;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 20;
  padding-top: 30px;
  padding-bottom: 10px;
  background: white;
  border-bottom: 1px solid #dee2e6;
}

.sticky-header-authenticated {
  top: 80px;


  @include media-breakpoint-up(md) {
    top: 110px;
  }

  @include media-breakpoint-up(xl) {
    top: 130px;
  }
}

.tooltip-color {
  z-index: 50;
  overflow: hidden;
  border-radius: 0.375rem;
  border: 1px solid;
  background-color: #000;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #fff;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

}