.weiss {
  background: #fff;
}

.silver {
  background: #b1b0b0;
}

.dunkelgrau {
  background: #747273;
}

.schwarz {
  background: black;
}

.umbra {
  background: #3c3225;
}

.vanille {
  background: #d8ccb0;
}

.crema {
  background: #ded5c7;
}

.berry {
  background: #d54545;
}

.olive {
  background: #907b30;
}

.lichtgrau {
  background: #d4d3d3;
}

.sand {
  background: #b6a88c;
}

.coffee {
  background: #614e3d;
}

.hellgrau {
  background: #cdcdce;
}

.lemon {
  background: #d3d634;
}

.aluminium-weiss {
  background: url('/public/thumbnails/css_sprites.png') -60px -12px;
}

.aluminium {
  background: url('/public/thumbnails/css_sprites.png') -12px -12px;
}

.chromstahl-geschliffen {
  background: url('/public/thumbnails/css_sprites.png') -12px -60px;
}

.chromstahl-poliert {
  background: url('/public/thumbnails/css_sprites.png') -60px -60px;
}

.eiche-relief {
  background: url('/public/thumbnails/css_sprites.png') -108px -12px;
}

.eternit {
  background: url('/public/thumbnails/css_sprites.png') -108px -60px;
}

.glas-indigo {
  background: url('/public/thumbnails/css_sprites.png') -12px -108px;
}

.glas-ornament {
  background: url('/public/thumbnails/css_sprites.png') -60px -108px;
}

.glas-schwarz {
  background: url('/public/thumbnails/css_sprites.png') -108px -108px;
}

.glas-weiss {
  background: url('/public/thumbnails/css_sprites.png') -156px -12px;
}

.marmor-weiss {
  background: url('/public/thumbnails/css_sprites.png') -156px -60px;
}

.messing-champagne {
  background: url('/public/thumbnails/css_sprites.png') -156px -108px;
}

.schiefer {
  background: url('/public/thumbnails/css_sprites.png') -12px -156px;
}

.schwarzgold-poliert {
  background: url('/public/thumbnails/css_sprites.png') -60px -156px;
}

.spiegel-satin {
  background: url('/public/thumbnails/css_sprites.png') -108px -156px;
}

.weissActive {
  color: #000;
}

.silverActive {
  color: #b1b0b0;
}

.dunkelgrauActive {
  color: #747273;
}

.schwarzActive {
  color: black;
}

.vanilleActive {
  color: #d8ccb0;
}

.cremaActive {
  color: #ded5c7;
}

.berryActive {
  color: #d54545;
}

.oliveActive {
  color: #907b30;
}

.lichtgrauActive {
  color: #d4d3d3;
}

.sandActive {
  color: #b6a88c;
}

.coffeeActive {
  color: #614e3d;
}

.hellgrauActive {
  color: #cdcdce;
}

.lemonActive {
  color: #d3d634;
}
